import { ConxSdkProviderContextType } from './types'
import Chai from 'types/chai'
import { v4 as uuidv4 } from 'uuid'
import Locales from 'types/locales'
import { AcceptedCountryCodes } from 'types/regions'

const userClientId = uuidv4()
export const mockConxSdkHook = (
  params?: Partial<ConxSdkProviderContextType>,
) => {
  jest.spyOn(require('./use-conx-sdk'), 'useConxSdk').mockImplementation(
    () =>
      ({
        locale: Locales.EN_GB,
        region: AcceptedCountryCodes.GB,
        sdkContext: getMockChaiContext(),
        userClientId,
        ...params,
      }),
  )
}

export const getMockChaiContext = (params?: Partial<Chai.Context>) => ({
  userPreferences: { set: jest.fn() as Chai.Context['userPreferences']['set'] } as Chai.Context['userPreferences'],
  ...params,
} as Chai.Context)

export const getMockChaiComposables = (params?: Partial<Chai.Composables>) => ({
  attachHarmReductionPager: () => { /* This comment makes lint not complain. */ },
  attachWarrantyPager: () => { /* This comment makes lint not complain. */ },
  ...params,
} as Chai.Composables)
